import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/img/logotipo2X.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';

type ValidateAccountProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const Privacy:FunctionComponent<{}> = () => {

  const { status } = useParams();

  const [mail, setMail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [workers, setWorkers] = useState('');
  const [ListCountries, setCountriesL] = useState([]);
  const [signining, setSignining] = useState(false);
  const [destinatario, setMailDest] = useState("");
  const [nombredestinatario, setDestina] = useState("");
  const [exito, setEnviado] = useState(false);
  const [countries, setCountries] = useState([]);
  const [locality, setLocality] = useState('');
  const [cargado, setCargado] = useState(false);

  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changeCountry = (e) => {
    const newMail = e.target.value;
    setCountry(newMail);
  }
  const changePhone = (e) => {
    const newMail = e.target.value;
    setPhone(newMail);
  }
  const changeEnterprise = (e) => {
    const newMail = e.target.value;
    setEnterprise(newMail);
  }
  const changeWorkers = (e) => {
    const newMail = e.target.value;
    setWorkers(newMail);
  }
  const changeName = (e) => {
    const newMail = e.target.value;
    setName(newMail);
  }
  const changeMessage = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      
    

      
    } catch (error) {

    }
    setLoading(false);
  };
  
  const handleFirstPass = (e) => {
    const { value } = e.target;
    setPassword(value);
  }

  const handleSecondPass = (e) => {
    const { value } = e.target;
    setRepeatPassword(value);
  }

  const getCountries = async () => {
    try {
      if (!cargado){
      const result = (await ObexRequestsHandler.get('/countries')).data;
      setCargado(true);
      setCountries(result);
      const countriesList = result.map(c => <option value={c.country_code} selected={locality == c.country_code}>{c.country}</option>);
      setCountriesL(countriesList);
      }
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }

  }

  const handleSubmitSignup =  async (event) => {

    if (mail.includes("@") && mail.includes(".") && password!="" && name!=""){

    
    event.preventDefault();
    event.stopPropagation();
    setSignining(true);
    setDestina(name);
    setMailDest(mail);

    const result = await ObexRequestHandler.post('/sendToMail', {'subject':'Question about X','to':destinatario,'message':password});

    const adminmessage="Nombre: "+name+"<br><br>"+"Email: "+mail+"<br><br>"+ 
                        "Empleados: "+workers+"<br><br>"+"Empresa: "+enterprise+"<br><br>"+
                        "Teléfono: "+phone+"<br><br>"+"Mensaje: "+password+"<br><br>";

    const result2 = await ObexRequestHandler.post('/sendToMail', {'subject':'Consulta acerca de X','to':"it@waynnovate.com",'message':adminmessage});

    setEnviado(true);
    setSignining(false);
    setTimeout(() => { 
      setEnviado(false)
    }, 6000);

    setName("");
    setEnterprise("");
    setPhone("");
    setWorkers("");
    setMail("");
    setPassword("");
  }
  else{
    setError("Nombre, email and message are mandatory, sorry.");
    setTimeout(() => { 
      setError("")
    }, 6000);
  }

  };

  useEffect(() => {
    getCountries();
  }, []);



  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100" style={{backgroundColor:'#013185'}}>
      <Row className="justify-content-center " >
        <div className="rounded px-5 pb-4" style={{ width : '800px', zIndex:9999}}>
          <Row className="justify-content-center " >
            <Col >
              <Image src={Logo} style={{height:'125px'}}/>
            </Col>
          </Row>
        </div>
      </Row>
      <Col style={{width:"60%", marginLeft:'20%', backgroundColor:'white', marginTop:'-90px', borderTopLeftRadius: '45px', borderTopRightRadius: '45px', height:'100%', overflow:'scroll'}}>
        <Col style={{marginRight:"10px", marginLeft:"10px", marginTop:'80px', textAlign:'initial'}}>


<Row>      <h2>Privacy Policy</h2> </Row> 

<Row><h5>What personal information do we collect from the people that visit our website or app?</h5></Row>

<Row>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address or other details to help you with your experience.</Row>

<Row style={{marginTop:'20px'}}><h5>When do we collect information?</h5></Row>

<Row>We collect information from you when you register on our site or enter information on our site.</Row>

<Row style={{marginTop:'20px'}}><h5>How do we use your information?</h5></Row>

<Row>We may use the information we collect from you when you register, make a purchase, surf the website, or use certain other site features in the following ways:</Row>

   <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
   <li>To improve our website in order to better serve you.</li>
   <li>To quickly process your transactions.</li>

<Row style={{marginTop:'20px'}}><h5>How do we protect your information?</h5></Row>

<li>We do not use vulnerability scanning and/or scanning to PCI standards.</li>
<li>We only provide articles and information. </li>
<li>We use regular Malware Scanning.</li>

<Row>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive information you supply is encrypted via Secure Socket Layer (SSL) technology.</Row>

<Row>We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.</Row>

<Row style={{marginTop:'20px'}}><h5>Do we use 'cookies'?</h5></Row>

<Row>No.</Row>
<Row style={{marginTop:'20px'}}><h5>Third-party disclosure</h5></Row>

<Row>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.</Row>

<Row style={{marginTop:'20px'}}><h5>Third-party links</h5></Row>

<Row>We do not include or offer third-party products or services on our website.</Row>

<Row style={{marginBottom:'60px'}}>If at any time you would like to unsubscribe from receiving future emails, you can email us at info@101obex.com and we will promptly remove you from ALL correspondence.</Row>

        </Col>
      </Col>

  
      <Row className="mt-auto">
        <Col className="px-0">
          <Footer/>
        </Col>
      </Row>

      


    </Container>
  );
}


export default Privacy;