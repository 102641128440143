import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/img/logotipo2X.png';
import diapo1 from '../../assets/img/diapo1.png';
import diapo2 from '../../assets/img/diapo2.png';
import diapo3 from '../../assets/img/diapo3.png';
import diapo4 from '../../assets/img/diapo4.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import "../../assets/css/carousel_custom.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

type ValidateAccountProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const MainPage:FunctionComponent<{}> = () => {

  const { status } = useParams();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      
    

      
    } catch (error) {

    }
    setLoading(false);
  };
  
  const handleFirstPass = (e) => {
    const { value } = e.target;
    setPassword(value);
  }

  const handleSecondPass = (e) => {
    const { value } = e.target;
    setRepeatPassword(value);
  }

  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100" style={{backgroundColor:'#013185'}}>

      <Row className="justify-content-center " >
        <div className="rounded px-5 pb-4" style={{ width : '800px', zIndex:9999}}>
          <Row className="justify-content-center " >
            <Col >
              <Image src={Logo} style={{height:'125px'}}/>
            </Col>
          </Row>
        </div>
      </Row>
      <Col style={{width:"60%", marginLeft:'20%', backgroundColor:'white', marginTop:'-90px', borderTopLeftRadius: '45px', borderTopRightRadius: '45px'}}>
<Row>
<Col style={{marginLeft:'27%', marginTop:'100px'}}>
      <Carousel showArrows={true} width={'60%'} autoPlay={true} dynamicHeight={true} infiniteLoop={true} showStatus={false} stopOnHover={false}>
                <div>
                    <img src={diapo1} />

                </div>
                <div>
                    <img src={diapo2} />

                </div>
                <div>
                    <img src={diapo3} />

                </div>
                <div>
                    <img src={diapo4} />

                </div>
            </Carousel>

            </Col>
            </Row>

            </Col>
      <Row className="mt-auto">
        <Col className="px-0">
          <Footer/>
        </Col>
      </Row>

    </Container>
  );
}


export default MainPage;