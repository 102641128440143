import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/img/xdefinitive-logo.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';

type ChangePasswordProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const ChangePassword:FunctionComponent<{}> = () => {

  const { token } = useParams();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');


  const ChangeObexPassword = async (password: any, token: any) => {
    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/users.py/auth_change_set_password?token=${token}&codigo_pais=ES&password=${password}`)
      console.log(result) 
      const estado = result["status"];
      //setsuccedChange(true)
      //setExitoError(estado)
    }catch (error) {
      console.error('ERROR SENDING VAULT ', error)
    }
  }
  

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      
      if (repeatPassword!=password){

        setError("Password and Repeat Password does not Match");


      } else {
        const {status, message} = await ObexProjectsRequestHandler.get(`/ws/users.py/auth_change_set_password?token=${token}&codigo_pais=ES&password=${password}`)

      if (!status) throw { message };

      }
    } catch (error) {
      console.error('ERROR WHILE CHANGING PASSWORD ', error);
      setError(error.message || error);
    }
    setLoading(false);
  };
  
  const handleFirstPass = (e) => {
    const { value } = e.target;
    setPassword(value);
  }

  const handleSecondPass = (e) => {
    const { value } = e.target;
    setRepeatPassword(value);
  }

  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100">
      <Row className="justify-content-center pt-5 mt-5">
        <div className="rounded px-5 pb-4" style={{ width : '800px'}}>
          <Row className="justify-content-center my-4 pb-5">
            <Col xs="4">
              <Image src={Logo} fluid/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control onChange={handleFirstPass} required type="password" placeholder="New Password"/>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control onChange={handleSecondPass} required type="password" placeholder="Repeat new Password"/>
                </Form.Group>
                <Button type="submit" className="my-2 login btn-block" >
                  Reset password
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                </Button>
                {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
                {success.length > 0 && (
                  <Alert variant='success'>
                    {success}
                  </Alert>)}
              </Form>
            </Col>
          </Row>
        </div>
      </Row>
      <Row className="mt-auto">
        <Col className="px-0">
          <Footer/>
        </Col>
      </Row>
    </Container>
  );
}


export default ChangePassword;