import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/img/logotipo2X.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';

type ValidateAccountProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const Contact:FunctionComponent<{}> = () => {

  const { status } = useParams();

  const [mail, setMail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [workers, setWorkers] = useState('');
  const [ListCountries, setCountriesL] = useState([]);
  const [signining, setSignining] = useState(false);
  const [destinatario, setMailDest] = useState("");
  const [nombredestinatario, setDestina] = useState("");
  const [exito, setEnviado] = useState(false);
  const [countries, setCountries] = useState([]);
  const [locality, setLocality] = useState('');
  const [cargado, setCargado] = useState(false);

  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changeCountry = (e) => {
    const newMail = e.target.value;
    setCountry(newMail);
  }
  const changePhone = (e) => {
    const newMail = e.target.value;
    setPhone(newMail);
  }
  const changeEnterprise = (e) => {
    const newMail = e.target.value;
    setEnterprise(newMail);
  }
  const changeWorkers = (e) => {
    const newMail = e.target.value;
    setWorkers(newMail);
  }
  const changeName = (e) => {
    const newMail = e.target.value;
    setName(newMail);
  }
  const changeMessage = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      
    

      
    } catch (error) {

    }
    setLoading(false);
  };
  
  const handleFirstPass = (e) => {
    const { value } = e.target;
    setPassword(value);
  }

  const handleSecondPass = (e) => {
    const { value } = e.target;
    setRepeatPassword(value);
  }

  const getCountries = async () => {
    try {
      if (!cargado){
      const result = (await ObexRequestsHandler.get('/countries')).data;
      setCargado(true);
      setCountries(result);
      const countriesList = result.map(c => <option value={c.country_code} selected={locality == c.country_code}>{c.country}</option>);
      setCountriesL(countriesList);
      }
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }

  }

  const handleSubmitSignup =  async (event) => {

    if (mail.includes("@") && mail.includes(".") && password!="" && name!=""){

    
    event.preventDefault();
    event.stopPropagation();
    setSignining(true);
    setDestina(name);
    setMailDest(mail);

    const result = await ObexRequestHandler.post('/sendToMail', {'subject':'Question about X','to':destinatario,'message':password});

    const adminmessage="Nombre: "+name+"<br><br>"+"Email: "+mail+"<br><br>"+ 
                        "Empleados: "+workers+"<br><br>"+"Empresa: "+enterprise+"<br><br>"+
                        "Teléfono: "+phone+"<br><br>"+"Mensaje: "+password+"<br><br>";

    const result2 = await ObexRequestHandler.post('/sendToMail', {'subject':'Consulta acerca de X','to':"it@waynnovate.com",'message':adminmessage});

    setEnviado(true);
    setSignining(false);
    setTimeout(() => { 
      setEnviado(false)
    }, 6000);

    setName("");
    setEnterprise("");
    setPhone("");
    setWorkers("");
    setMail("");
    setPassword("");
  }
  else{
    setError("Nombre, email and message are mandatory, sorry.");
    setTimeout(() => { 
      setError("")
    }, 6000);
  }

  };

  useEffect(() => {
    getCountries();
  }, []);



  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100" style={{backgroundColor:'#013185'}}>
      <Row className="justify-content-center " >
        <div className="rounded px-5 pb-4" style={{ width : '800px' , zIndex:9999}}>
          <Row className="justify-content-center " >
            <Col >
              <Image src={Logo} style={{height:'125px'}}/>
            </Col>
          </Row>
        </div>
      </Row>

      <Col style={{width:"60%", marginLeft:'20%', backgroundColor:'white', marginTop:'-90px', borderTopLeftRadius: '45px', borderTopRightRadius: '45px'}}>
      <Row style={{marginTop:"125px", marginBottom:'125px'}}>

        <Col style={{maxWidth:'70%', marginLeft:'15%'}}>
          <Row style={{justifyContent:'center'}}><span className="contact">Contact</span></Row>
          {/*<Row><span className="direction">18006 Jose Luis Perez Pujadas, Oficina B43 Edificio Forum</span></Row>*/}



          
          <Form className="obex-form" style={{marginTop:'80px'}}>
                  Name
                  <Form.Group controlId="formBasicName">
                    <Form.Control required placeholder="Type your name" onChange={changeName} value={name}/>
                  </Form.Group>
                  {/*}
                  Nombre de la empresa
                  <Form.Group controlId="formBasicEnterprise">
                    <Form.Control required placeholder="Ingrese el nombre de la empresa" onChange={changeEnterprise} value={enterprise}/>
                  </Form.Group>
                  Teléfono
                  <Form.Group controlId="formBasicPhone">
                    <Form.Control required type="phone" placeholder="Ingrese su teléfono" onChange={changePhone} value={phone}/>
                  </Form.Group>
                */}
                  Select your country
                  <Form.Group controlId="formBasicCountry">
                    <Form.Control required as="select" placeholder='Pais' custom onChange={null}>
                          <option value="" selected disabled hidden>Select your country</option>
                            {ListCountries}
                    </Form.Control>
                  </Form.Group>
                  {/*
                  Cantidad de empleados
                  <Form.Group controlId="formBasicWorkers">
                    <Form.Control placeholder="Cantidad de empleados" onChange={changeWorkers} value={workers}/>
                  </Form.Group>
                */}
                  Email
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control required type="email" placeholder="type your mail" onChange={changeMail} value={mail}/>
                  </Form.Group>
                  Message
                  <Form.Group controlId="formBasicMessage">

                  <Form.Control required as="textarea" rows={3} placeholder="" onChange={changeMessage} value={password} />
                  </Form.Group>
                  <Button type="submit" className="my-2 plans_button  btn-block button_blured" onClick={handleSubmitSignup} disabled={signining}>
                    {signining ? "Sending" : "Send"}
                    {signining && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                  {error.length > 0 && (
                    <Alert variant='danger'>
                      {error}
                    </Alert>)}
                    {exito && (
                    <Alert variant='success'>
                     Thank you very much for contacting us {nombredestinatario}, you will receive response in {destinatario} inbox.
                    </Alert>)}
            </Form>
          
            </Col>
                    
        </Row>

                      </Col>


      <Row className="mt-auto">
        <Col className="px-0">
          <Footer/>
        </Col>
      </Row>

      


    </Container>
  );
}


export default Contact;